var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("guide", {
        ref: "guide",
        attrs: {
          addPermissionString: "gz:add",
          editPermissionString: "gz:edit",
          deletePermissionString: "gz:delete",
          viewPermissionString: "gz:view",
          "is-preview": "",
          "is-mask-closable": "",
          "hide-edit-btn": "",
          propsFilters: { typeId: 2 },
        },
        on: { loadData: _vm.loadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }